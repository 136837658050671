:root {
    /* hopefully the MUI colors */
    --pageBackgroundColor: #f8f9fc;
    --primaryColor: #2D5AF0;
    --secondaryColor: #8F94B6;

    --activeBgr: #c60f2f;
    --darkGreyBgr: #565656;
    --pageHeaderBgr: #2a3031;
    --blackRGBABgr: rgba(0, 0, 0, 0.5);
    --blackRGBABgrModalVideo: rgba(0, 0, 0, 0.9);
    --contentBackgroundColor: #f0f3f8;
    --sidebarBgr: #181f47;
    --sidebarActiveBgr: #101634;
    --sidebarIconColor: #8F94B6;
    --sidebarActiveIconColor: #F59F1E;
    --sidebarSubIconColor: #b4dffa;
    --sidebarActiveSubIconColor: #b7ddac;

    /* Hover */
    --itemHover: #d5e2ff;

    /* Shape */
    --commonBorderRadius: 14px;
    --selectMenuBorderRadius: 4px;
    --sectionPadding: 56px;
    --mobileSectionPadding: 20px;

    /* Error */
    --error: #c60f2f;

    /* Common */
    --formColor: #010101;
    --white: #fff;
    --black: #111;
    --activeSidebarTab: #c60f2f;
    --activeColor: #c60f2f;
    --darkGreyColor: #565656;
    --grey: #9a9a9a;
    --lightGrey: #ededed;
    --sectionStripeGrey: #f6f6f6;
    --titleGrey: #616161;
    --textGrey: #b6b9bc;
    --lightBlue: #007aff;
    --tableBorder: #dee2e6;
    --brightGreen: #06d22f;
    --tableTextColor: #050505;
    --spinnerColor: #2548e6;
    --grey300: #c7c7d9;
    --darkBlue: #163ec0;
    --thinBlue: #6a8dff;
}

body {
    margin: 0;
    font-family: 'Open Sans', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-y: hidden;
    color: var(--black);
    font-size: 14px;
    font-weight: 600;
}

code {
    font-family: source-code-pro, 'Open Sans', Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/*bootstrap*/
.dropdown-toggle,
.dropdown-menu {
    width: 100%;
    text-align: left;
    border: 1px solid #9a9a9a !important;
}

.dropdown-menu span {
    white-space: normal;
}

.btn {
    display: flex !important;
    text-align: unset !important;
    justify-content: space-between;
    align-items: center;
}

.btn.focus,
.btn:focus {
    box-shadow: unset !important;
}

img,
video {
    flex-shrink: 0;
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    border: none;
}

.c-pointer {
    cursor: pointer;
}

.visually-hidden {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    border: 0;
    padding: 0;
    clip: rect(0, 0, 0, 0);
    overflow: hidden;
}
