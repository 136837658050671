/* Custom Light Reset */
html {
  box-sizing: border-box;
  scroll-behavior: smooth;
}

*,
::before,
::after {
  box-sizing: inherit;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
ul,
ol,
p,
p:empty,
label,
figure {
  margin: 0;
}

ul,
button,
p:empty {
  padding: 0;
}

ul {
  list-style: none;
  list-style-type: none;
}

input,
button,
textarea {
  margin: 0; /* For Safari */
  font-family: inherit;
  font-style: normal;
  letter-spacing: inherit;
  color: inherit;
  background-color: transparent;
  border: none;
  border-radius: 0;
}

input:focus,
input:active,
textarea:focus,
textarea:active,
select:focus,
select:active,
button:focus,
button:active,
video:focus {
  outline: none !important;
}

a {
  text-decoration: none;
}

a:hover,
a:focus,
a:active,
a:visited {
  text-decoration: none;
  outline: none;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
