@keyframes moveIn {
  0% {
    transform: translateX(100%);
  }
  60% {
    transform: translateX(-20px);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes moveOut {
  0% {
    transform: translateX(0);
  }
  60% {
    transform: translateX(-20px);
  }
  100% {
    transform: translateX(100%);
  }
}
